import { checkSupabaseSession } from "./rendering/render-github-login-button";
import { getReferralFromUser } from "./getters/get-github-referrals";

export function initiateDevRelTracking() {
  const oldDevRelCode = sessionStorage.getItem("devRel");
  if (!oldDevRelCode) {
    const urlParams = new URLSearchParams(window.location.search);
    const devRelCode = urlParams.get("ref");
    if (devRelCode) {
      sessionStorage.setItem("devRel", devRelCode);
    }
  }
}

export async function trackReferralCode() {
  const refCode = sessionStorage.getItem("devRel");

  if (refCode && refCode!="done") {
    const url = "/referral-manager";

    const supabaseAuth = await checkSupabaseSession();

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authToken: supabaseAuth.provider_token,
        referralCode: refCode,
      }),
    });

    if (response.status === 200) {
      localStorage.setItem("devRel", "done");
      const newURL = new URL(window.location.href);
      newURL.searchParams.delete("ref");
      window.history.pushState({}, "", newURL.toString());
    } else {
      console.error(`Failed to set referral. Status: ${response.status}`);
    }
  }
}
